

import AssetCard from "@/components/assets/AssetCard"
import InspectionList from "@/components/inspections/InspectionList"
import Loader from "@/components/Loader"
import { mapState } from 'vuex';

export default {
  name: "Asset",
  components: {
    AssetCard,
    InspectionList,
    Loader,
  },
  computed: {
    ...mapState('assets__new', [
      'getAssetsResult',
      'getAssetsIsLoading',
      'getAssetsItemResult',
      'getAssetsItemIsLoading',
    ]),
    ...mapState('inspections__new', [
      'getInspectionsResult',
      'getInspectionsIsLoading',
    ]),
    assetId() {
      return parseInt(this.$route.params.asset_id, 10);
    },
    isLoading() {
      return !this.asset || !this.inspections || this.getAssetsIsLoading || this.getAssetsItemIsLoading || this.getInspectionsIsLoading;
    },
    asset() {
      let asset = null;
      if (!this.getAssetsResult && !this.getAssetsItemResult) {
        return null;
      }
      if (this.getAssetsResult) {
        asset = this.getAssetsResult.find(asset => asset.id === this.assetId);
      } else if (this.getAssetsItemResult && this.getAssetsItemResult.id === this.assetId) {
        asset = this.getAssetsItemResult;
      }
      return asset;
    },
    inspections() {
      const inspections = this.getInspectionsResult?.filter(x => x.asset === this.assetId);
      if (!inspections) {
        return null;
      }
      return inspections;
    },
  },
  beforeMount() {
    if (!this.getAssetsResult) {
      this.$store.dispatch('assets__new/getAssetsItem', { id: this.assetId });
    }
    if (!this.getInspectionsResult) {
      this.$store.dispatch('inspections__new/getInspections');
    }
  },
}
